// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\templates\\blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-2020-blackberry-woods-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\pages\\2020-blackberry-woods.js" /* webpackChunkName: "component---src-pages-2020-blackberry-woods-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-elements-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\pages\\elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\tdpso\\Dev\\GitHub\\Personal\\photos\\.cache\\data.json")

